<template>
  <v-row class="progress">
    <v-col cols="12" class="pa-0">
      <div class="title">
        <div class="text-sm-h5 text-h6 font-weight-bold">
          <router-link to="/groups" class="white--text">
            <v-icon class="white--text text-sm-h4 text-h5 mb-1">mdi-chevron-left</v-icon>{{ group.name }}
          </router-link>
        </div>
      </div>
      <v-card
        v-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
      <v-card v-else elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5">
        <div class="text-uppercase text-h5 font-weight-bold">Adding contacts to the group</div>
        <div class="d-flex flex-wrap justify-space-between mt-2">
          <div class="opasity--text">You can leave this page, contacts will be imported in the background</div>
          <div class="mx-md-0 mx-auto mt-md-0 mt-2">
            <v-btn v-if="data.status != 'finished'" @click="refresh" width="184" height="48" elevation="0" color="secondary">
              <v-icon left>mdi-refresh mdi-24px</v-icon> Refresh
            </v-btn>
            <v-btn @click="back" v-else width="184" height="48" elevation="0" color="primary">New Import</v-btn>
          </div>
        </div>
        <div class="px-sm-10 py-sm-5 px-2 mt-sm-10 mt-5">
          <v-row align="center">
            <v-col cols="12" md="6" class="pa-sm-0 text-center">
              <v-progress-circular :rotate="-90" :size="310" :width="40" :value="data.progress">
                <div class="percent-value rounded-circle d-flex justify-center align-center">
                  <div>
                    <div class="text-h4 font-weight-bold">{{ data.progress }}%</div>
                    <div class="primary--text text-capitalize font-weight-bold">{{ data.status }}</div>
                  </div>
                </div>
              </v-progress-circular>
            </v-col>
            <v-col cols="12" md="6" class="pa-sm-0 px-7">
              <v-row class="mt-md-0 mt-10">
                <v-col cols="6" class="pa-0">
                  <div class="text-h5">{{ data.lines_processed }}</div>
                  <div class="opasity--text">Lines read from file</div>
                </v-col>
                <v-col cols="6" class="pa-0">
                  <div class="text-h5">{{ data.contacts_added }}</div>
                  <div class="opasity--text">Contacts added</div>
                </v-col>
              </v-row>
              <v-row class="mt-10">
                <v-col cols="6" class="pa-0">
                  <div class="text-h5">{{ data.duplicates_found }}</div>
                  <div class="opasity--text">Duplicates found</div>
                </v-col>
                <v-col cols="6" class="pa-0">
                  <div class="text-h5" :class="!data.phone_errors_count ? '' : 'error--text'">{{ data.phone_errors_count }}</div>
                  <div class="opasity--text">Phone errors</div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div class="opasity--text f18 font-weight-medium mt-10 ml-4">Import errors</div>
        <v-divider></v-divider>
        <v-virtual-scroll v-if="data.phone_errors_count" :items="data.phone_errors" :item-height="64" height="384">
          <template v-slot:default="{ item }">
            <div class="f18 clip py-4">{{ item }}</div>
            <v-divider></v-divider>
          </template>
        </v-virtual-scroll>
        <div v-else class="f-18 font-weight-medium ml-4">No Errors found</div>
        <div v-if="data.phone_errors_count" class="text-right mt-5">
          <v-btn @click="copy" color="primary" elevation="0" height="48" width="184">Copy</v-btn>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      load: true,
      percent: 0,
      timerID: null,
    };
  },
  beforeCreate() {
    this.$store.dispatch('setId', this.$route.params.groupId);
  },
  mounted() {
    this.$store.dispatch('getGroup').finally(() => {
      this.load = false;
    });
    this.getData();
  },
  methods: {
    notifi(btn) {
      if (btn == 'copy') {
        this.$notify({ message: 'Data copied', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'copyError') {
        this.$notify({ message: 'Sorry, error copied', type: 'error', horizontalAlign: 'center', verticalAlign: 'top' });
      }
    },
    async getData() {
      await this.$store.dispatch('importProgContants');
    },
    async refresh() {
      await this.$store.dispatch('importProgContantsBtn');
    },
    copy() {
      const string = this.data.phone_errors.join('\n');
      this.$copyText(string)
        .then(() => {
          this.notifi('copy');
        })
        .catch(() => {
          this.notifi('copyError');
        });
    },
    back() {
      this.$router.push({ name: 'Group', params: { groupId: this.group.id } });
    },
  },
  computed: {
    group() {
      return this.$store.getters.group;
    },
    data() {
      return this.$store.getters.importProgress;
    },
  },
  destroyed() {
    this.$store.dispatch('setGroup', {});
    this.$store.dispatch('setImportProgContants', {});
  },
};
</script>

<style lang="scss">
.progress {
  circle {
    color: #577bf9;
  }
  .percent-value {
    width: 175px;
    height: 175px;
    background: #ececec;
    box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.04);
  }
}
</style>
